import "@popperjs/core";
//import "bootstrap";
//import "bootstrap/dist/js/bootstrap.bundle.js";
//import { Modal } from 'bootstrap';
//import { Toast, Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';
//import * as Bootstrap from 'bootstrap';
const bootstrap = window.bootstrap = require('bootstrap');

document.addEventListener("DOMContentLoaded", function(){

    //console.log("CRM Detalum Webpack");

    // Выпадающее меню 2-го уровня
    let sidebarCollapsedLinks = document.querySelectorAll('#navbarMain .second-wrap>.nav-link');
    sidebarCollapsedLinks.forEach(function(link, index) {
        link.addEventListener('click', function(e) {
            this.parentNode.classList.toggle('open');
        });
    });
    

    // Главное меню на мобильном
    const btnMenu = document.querySelector('.main-sidebar .navbar-toggler');
    const menu = document.querySelector('.offcanvas-collapse');
    const toggleMenu = function() {
        menu.classList.toggle('open');
    }
    btnMenu.addEventListener('click', function(e) {
        e.stopPropagation();
        toggleMenu();
    });
    document.addEventListener('click', function(e) {
        const target = e.target;
        const its_menu = target == menu || menu.contains(target);
        const its_btnMenu = target == btnMenu;
        const menu_is_active = menu.classList.contains('open');

        if (!its_menu && !its_btnMenu && menu_is_active) {
            toggleMenu();
        }
    });

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })
});

